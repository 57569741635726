export const POST_INCIDENT_COMMENT = "POST_INCIDENT_COMMENT";
export const POST_INCIDENT_COMMENT_SUCCESS = "POST_INCIDENT_COMMENT_SUCCESS";
export const POST_INCIDENT_COMMENT_ERROR = "POST_INCIDENT_COMMENT_ERROR";

export const REQUEST_WORKFLOW_UPDATE = "REQUEST_WORKFLOW_UPDATE";
export const REQUEST_WORKFLOW_UPDATE_SUCCESS = "REQUEST_WORKFLOW_UPDATE_SUCCESS";
export const REQUEST_WORKFLOW_UPDATE_ERROR = "REQUEST_WORKFLOW_UPDATE_ERROR";

export const INCIDENT_ATTACH_FILE_REQUEST = 'INCIDENT_ATTACH_FILE_REQUEST';
export const INCIDENT_ATTACH_FILE_SUCCESS = 'INCIDENT_ATTACH_FILE_SUCCESS';
export const INCIDENT_ATTACH_FILE_ERROR = 'INCIDENT_ATTACH_FILE_ERROR';
