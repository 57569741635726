export const REQUEST_INCIDENT_CHANNELS = 'REQUEST_INCIDENT_CHANNELS';
export const REQUEST_INCIDENT_CHANNELS_SUCCESS = 'REQUEST_INCIDENT_CHANNELS_SUCCESS';
export const REQUEST_INCIDENT_CHANNELS_FAILURE = 'REQUEST_INCIDENT_CHANNELS_FAILURE';

export const REQUEST_INCIDENT_ELECTIONS = 'REQUEST_INCIDENT_ELECTIONS';
export const REQUEST_INCIDENT_ELECTIONS_SUCCESS = 'REQUEST_INCIDENT_ELECTIONS_SUCCESS';
export const REQUEST_INCIDENT_ELECTIONS_FAILURE = 'REQUEST_INCIDENT_ELECTIONS_FAILURE';

export const REQUEST_INCIDENT_INSTITUTIONS = 'REQUEST_INCIDENT_INSTITUTIONS';
export const REQUEST_INCIDENT_INSTITUTIONS_SUCCESS = 'REQUEST_INCIDENT_INSTITUTIONS_SUCCESS';
export const REQUEST_INCIDENT_INSTITUTIONS_FAILURE = 'REQUEST_INCIDENT_INSTITUTIONS_FAILURE';

export const REQUEST_INCIDENT_CATAGORIES = 'FETCH_INCIDENT_CATAGORIES_REQUEST';
export const REQUEST_INCIDENT_CATAGORIES_SUCCESS = 'FETCH_INCIDENT_CATAGORIES_SUCCESS';
export const REQUEST_INCIDENT_CATAGORIES_FAILURE = 'FETCH_INCIDENT_CATAGORIES_ERROR';

export const REQUEST_INCIDENT_PROVINCES = 'REQUEST_INCIDENT_PROVINCES';
export const REQUEST_INCIDENT_PROVINCES_SUCCESS = 'REQUEST_INCIDENT_PROVINCES_SUCCESS';
export const REQUEST_INCIDENT_PROVINCES_FAILURE = 'REQUEST_INCIDENT_PROVINCES_FAILURE';

export const REQUEST_INCIDENT_DISTRICTS = 'REQUEST_INCIDENT_DISTRICTS';
export const REQUEST_INCIDENT_DISTRICTS_SUCCESS = 'REQUEST_INCIDENT_DISTRICTS_SUCCESS';
export const REQUEST_INCIDENT_DISTRICTS_FAILURE = 'REQUEST_INCIDENT_DISTRICTS_FAILURE';

export const REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS = 'REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS';
export const REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS_SUCCESS = 'REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS_SUCCESS';
export const REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS_FAILURE = 'REQUEST_INCIDENT_DIVISIONAL_SECRETARIATS_FAILURE';

export const REQUEST_INCIDENT_GRAMA_NILADHARIS = 'REQUEST_INCIDENT_GRAMA_NILADHARIS';
export const REQUEST_INCIDENT_GRAMA_NILADHARIS_SUCCESS = 'REQUEST_INCIDENT_GRAMA_NILADHARIS_SUCCESS';
export const REQUEST_INCIDENT_GRAMA_NILADHARIS_FAILURE = 'REQUEST_INCIDENT_GRAMA_NILADHARIS_FAILURE';

export const REQUEST_INCIDENT_POLLING_STATIONS = 'REQUEST_INCIDENT_POLLING_STATIONS';
export const REQUEST_INCIDENT_POLLING_STATIONS_SUCCESS = 'REQUEST_INCIDENT_POLLING_STATIONS_SUCCESS';
export const REQUEST_INCIDENT_POLLING_STATIONS_FAILURE = 'REQUEST_INCIDENT_POLLING_STATIONS_FAILURE';

export const REQUEST_INCIDENT_POLLING_DIVISIONS = 'REQUEST_INCIDENT_POLLING_DIVISIONS';
export const REQUEST_INCIDENT_POLLING_DIVISIONS_SUCCESS = 'REQUEST_INCIDENT_POLLING_DIVISIONS_SUCCESS';
export const REQUEST_INCIDENT_POLLING_DIVISIONS_FAILURE = 'REQUEST_INCIDENT_POLLING_DIVISIONS_FAILURE';

export const REQUEST_INCIDENT_POLICE_STATIONS = 'REQUEST_INCIDENT_POLICE_STATIONS';
export const REQUEST_INCIDENT_POLICE_STATIONS_SUCCESS = 'REQUEST_INCIDENT_POLICE_STATIONS_SUCCESS';
export const REQUEST_INCIDENT_POLICE_STATIONS_FAILURE = 'REQUEST_INCIDENT_POLICE_STATIONS_FAILURE';

export const REQUEST_INCIDENT_POLICE_DIVISIONS = 'REQUEST_INCIDENT_POLICE_DIVISIONS';
export const REQUEST_INCIDENT_POLICE_DIVISIONS_SUCCESS = 'REQUEST_INCIDENT_POLICE_DIVISIONS_SUCCESS';
export const REQUEST_INCIDENT_POLICE_DIVISIONS_FAILURE = 'REQUEST_INCIDENT_POLICE_DIVISIONS_FAILURE';

export const REQUEST_INCIDENT_WARDS = 'REQUEST_INCIDENT_WARDS';
export const REQUEST_INCIDENT_WARDS_SUCCESS = 'REQUEST_INCIDENT_WARDS_SUCCESS';
export const REQUEST_INCIDENT_WARDS_FAILURE = 'REQUEST_INCIDENT_WARDS_FAILURE';

export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_REQUEST_SUCCESS = 'SIGN_IN_REQUEST_SUCCESS';
export const SIGN_IN_REQUEST_ERROR = 'SIGN_IN_REQUEST_ERROR';

export const SIGN_IN_REFRESH_TOKEN_REQUEST = 'SIGN_IN_REFRESH_TOKEN_REQUEST';
export const SIGN_IN_REFRESH_TOKEN_REQUEST_SUCCESS = 'SIGN_IN_REFRESH_TOKEN_REQUEST_SUCCESS';
export const SIGN_IN_REFRESH_TOKEN_REQUEST_ERROR = 'SIGN_IN_REFRESH_TOKEN_REQUEST_ERROR';

export const TOGGLE_REMEBER_USER = 'TOGGLE_REMEBER_USER';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const RESET_ACTIVE_INCIDENT = "RESET_ACTIVE_INCIDENT";

export const REQUEST_INCIDENT_POLITICAL_PARTIES = "REQUEST_INCIDENT_POLITICAL_PARTIES";
export const REQUEST_INCIDENT_POLITICAL_PARTIES_SUCCESS = "REQUEST_INCIDENT_POLITICAL_PARTIES_SUCCESS";
export const REQUEST_INCIDENT_POLITICAL_PARTIES_FAILURE = "REQUEST_INCIDENT_POLITICAL_PARTIES_FAILURE";