
import { createAction } from 'redux-starter-kit';



//stepper movement actions
export const moveStepper = createAction('GUEST_VIEW/MOVE_STEPPER');










